import React from 'react';
import './App.css';
import YourPetCards from './YourPetCards.js';
import { Routes, Route } from "react-router-dom"
import loadable from '@loadable/component'

const PrivacyPolicy = loadable(() => import('./PrivacyPolicy.js'))
const OrderConfirmation = loadable(() => import('./OrderConfirmation.js'))
const TermsOfService = loadable(() => import('./TermsOfService.js'))


function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <Routes>
        <Route path="/" element={ <YourPetCards/>} />
        <Route path="/terms-of-service" element={ <TermsOfService/>} />
        <Route path="/order-confirmation" element={ <OrderConfirmation/>} />
        <Route path="/privacy-policy" element={ <PrivacyPolicy/>} />
        <Route path="*" element={ <YourPetCards/>} />
      </Routes>
    </div>
  );
}

export default App;
