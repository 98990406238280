import React from 'react';
import Grid from '@mui/material/Grid';
import Carousel from 'react-material-ui-carousel'


function SliderImage(props) {
  return (
    <Grid item className = {props.className}
      sx={{
        pl:2,
        pr:2
      }}
    >
    </Grid>
  );
}
const items = [<SliderImage className='explainer_one' key='1'/>,
 <SliderImage className='explainer_two' key='2'/>,
 <SliderImage className='explainer_three' key='3'/>,
 <SliderImage className='explainer_four' key='4'/>,
]

const Explainer = (props) => (

  <Grid container direction="column" id = "explainer" sx={{height:'50vh'}} >
    <Carousel autoPlay={false}>
      {items}
    </Carousel>
  </Grid>

);

export default Explainer;
