import React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

const Hero = (props) => {
  const matches = useMediaQuery('(min-width:600px)')

  return(
    <Grid container direction="column" >
      <Grid item className='hero'>
      </Grid>
      <Grid item>
        <Stack
          sx={{ pt: matches ? 4 : 0 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" href="#buyButtonArea">Get Started</Button>
          <Button variant="outlined" href="#InteractiveExamples">See Examples</Button>
        </Stack>
      </Grid>
    </Grid>
  )

};

export default Hero;
