import React from 'react';
import AppBar from '@mui/material/AppBar';
import PetsIcon from '@mui/icons-material/Pets';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { purple } from '@mui/material/colors';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import ScienceIcon from '@mui/icons-material/Science';

import Hero from './Hero.js';
import Explainer from './Explainer.js'
import loadable from '@loadable/component'

const CheckoutButton = loadable(() => import('./CheckoutButton.js'))
const BuyJustImagesButton = loadable(() => import('./BuyJustImagesButton.js'))
const InteractiveExamples = loadable(() => import('./InteractiveExamples.js'))
const EmailSignUp = loadable(() => import('./EmailSignUp.js'))
const FAQ = loadable(() => import('./FAQ.js'))



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://yourpetcards.com/">
        yourpetcards.com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const theme = createTheme({
  palette: {
    primary: {
      main: purple[300],
    },
    secondary: {
      main: '#422547',
      dark: '#C986D1',
      light: '#CBABD0'
    }
  },
  typography: {
    fontFamily:'Lato'
  }
});

export default function YourPetCards() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="relative">
        <Toolbar>
          <PetsIcon sx={{ mr: 2 }} />
          <Typography variant="h6" color="inherit" noWrap>
            Your Pet Cards
          </Typography>
        </Toolbar>
      </AppBar>
      <main>
        <Hero/>


          <Container sx={{ pt: 6, pb:1 }} maxWidth="md">
          <Typography
            component="h1"
            variant="h2"
            align="center"
            color="text.primary"
            gutterBottom
          >
          Imagine Your Pet Doing Anything!
          </Typography>
          <Typography variant="h5" align="center" color="text.primary" paragraph>
          <UploadFileIcon sx={{ mr: 1 }} />
          Upload a few photos of your pet.
          </Typography>
          <Typography variant="h5" align="center" color="text.primary" paragraph>
          <ScienceIcon sx={{ mr: 1 }} />
          Receive 40 unique AI-generated images of your pet in any scenario.
          </Typography>
          <Typography variant="h5" align="center" color="text.primary" paragraph>
          <PanToolAltIcon sx={{ mr: 1 }} />
          Choose your favorite and order a set of cards to receive in the mail.
          </Typography>
          <Typography variant="h5" align="center" color="text.primary" paragraph>
          <EmojiEmotionsIcon sx={{ mr: 1 }} />
          Not looking for cards? Just buy images!
          </Typography>
          </Container>

        <Container sx={{ py: 1 }} maxWidth="lg">
        <Explainer/>
        </Container>

        <InteractiveExamples/>
        <FAQ/>


          <Container sx={{ py: 8, ml:0, mr:0, bgcolor:"primary.main" }} maxWidth={false} id = "buyButtonArea">
          <Typography variant="h4" align="center" color="white" paragraph>
            Transform your pet!
          </Typography>
          <CheckoutButton/>
          <BuyJustImagesButton/>
          </Container>
      </main>
      {/* Footer */}
      <Container sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
      <EmailSignUp/>
      <Typography variant="h5" align="center" color="text.primary" paragraph>
        Have a question or want to get in touch?
        <Link href="mailto:hi@yourpetcards.com" underline="none" >
         &nbsp;Contact Us
       </Link>
      </Typography>
        <Typography
          variant="subtitle1"
          align="center"
          color="text.secondary"
          component="p"
        >
          Boston, MA
        </Typography>
        <Copyright />
        </Container>
      {/* End footer */}
    </ThemeProvider>
  );
}
